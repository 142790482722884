<script setup lang="ts">
definePageMeta({ isPublic: true, layout: 'empty' })

const { volunteerCenters } = await fetchVolunteerCenters()
</script>

<template>
  <div
    class="bg-muted-100 dark:bg-muted-800 flex size-full flex-col items-center justify-center p-4"
  >
    <img
      src="/img/logos/logo-gelderse-handen.png"
      alt=""
      class="my-10 max-w-sm"
    >
    <BaseProse>
      <BaseParagraph> Beste bezoeker, </BaseParagraph>
      <BaseParagraph>
        Welkom op de landingspagina van wat we voorheen Gelderse Handen
        noemden!
      </BaseParagraph>
      <BaseParagraph>
        Met een groep van 9 vrijwilligerscentrales/-steunpunten in de regio
        hebben we een nieuwe opzet gekozen: we hebben nu onze eigen, meer
        gebruikersvriendelijke, websites met een frissere opmaak. Met focus op
        jouw eigen gemeente!
      </BaseParagraph>
      <BaseParagraph>
        Welke centrale, steunpunt of gemeente wil jij bezoeken?<br>
        Klik op een van de logo’s en vind de centrale of het steunpunt in jouw
        gemeente.
      </BaseParagraph>
    </BaseProse>

    <div class="mb-10 grid grid-cols-1 gap-4 md:grid-cols-3">
      <template v-for="center in volunteerCenters" :key="center.id">
        <NuxtLink
          :to="center.website || '#'"
          class="hover:border-primary-500 flex h-64 flex-col items-center rounded-xl border-2 border-solid bg-white p-4 px-6 text-center"
          @click=""
        >
          <BaseHeading as="span" class="dark:text-muted-900">
            {{
              center.municipality
            }}
          </BaseHeading>
          <img
            class="mt-2 max-h-40"
            :src="center.logoUrl"
            :alt="center.name"
          >
        </NuxtLink>

        <NuxtLink
          v-if="center.municipality === 'Overbetuwe'"
          to="https://www.vrijwilligerswerkrheden.nl"
          class="hover:border-primary-500 flex h-64 flex-col items-center rounded-xl border-2 border-solid bg-white p-4 px-6 text-center"
        >
          <BaseHeading
            as="span"
            class="dark:text-muted-900"
          >
            Rheden
          </BaseHeading>
          <img
            class="mt-2 max-h-40"
            src="/img/logos/logo-rheden.png"
            alt="Rheden"
          >
        </NuxtLink>
      </template>
    </div>
  </div>
</template>
